<script>
import store from '@/infrastructure/store'
import NotificationsList from '@/features/notifications/components/NotificationsList'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'

export default {
  components: {
    NotificationsList,
  },

  setup() {
    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('Notifications:MarkedRead', () => store.dispatch('notifications/fetchReadNotifications'))
  },

  data() {
    return {
      isLoadingRead: false,
    }
  },

  computed: {
    readNotifications: () => store.getters['notifications/readNotifications'],
  },

  mounted() {
    this.loadReadNotifications()
  },

  methods: {
    async loadReadNotifications() {
      this.isLoadingRead = true
      try {
        await store.dispatch('notifications/fetchReadNotifications')
      } finally {
        this.isLoadingRead = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoadingRead">
      <v-card-title>
        <h4 class="mx-auto">Read notifications</h4>
      </v-card-title>
    </v-card>

    <NotificationsList :notifications="readNotifications" item-css-class="mt-3" />
  </div>
</template>
